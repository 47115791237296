.swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    height: calc((100% - 20px) / 2) !important;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.swiper-image {
    width: 98%; /* Adjust this value to make the image larger or smaller */
    height: auto; /* Maintain the aspect ratio of the image */
}

.swiper-pagination-bullet {
    background-color: grey; /* Change to your desired color */
  }

  .swiper-container {
    position: relative;
  }
  
  .swiper-pagination {
    position: absolute;
    left: 50%;
    transform: translateY(50%);
  }